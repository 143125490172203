import { Suspense } from 'react';
import LoadingSpinner from '../../components/loader/loader';
import DashboardLayout from '../../components/layout/dashboard/dashboard-layout';

import './root-page.scss';

const RootPage = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <div className="govuk-width-container box">
          <div className="govuk-grid-row wrapper">
            Welcome to the Access Community Gateway<br/><br/><br/>
            If you are trying to log in, please check the address/URL with your local authority<br/><br/>
            <span>This would normally be accesscommunitygateway.com/<b>yourlocalauthority</b></span>
          </div>
        </div>
      </DashboardLayout>
    </Suspense>
  );
}

export default RootPage;