import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import formLookupService from '../../services/form-lookup.service';

export const getFormJSON = createAsyncThunk(
  'formtemplates/getFormJSON',
  async ({ schemaId }: { schemaId: string }, thunkApi) => {
    try {
      const response = await formLookupService.getForm(schemaId);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FormJSONState {
  loading: boolean;
  error: string | null;
  formJSON: string | null;
}

const initialState = {
  loading: false,
  error: null,
  formJSON: null,
} as FormJSONState;

const formJSONSlice = createSlice({
  name: 'forms-json',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFormJSON.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormJSON.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.formJSON = action.payload;
      })
      .addCase(getFormJSON.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.formJSON = '';
      });
  },
});

const { reducer } = formJSONSlice;
export default reducer;
