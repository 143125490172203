import { useRadioButtonStateContext } from '../../../../../context/form-context';
import { OptionsModel } from '../../../../../models/formSchema/options';
import { useState } from 'react';
import RadioButton from './radioButton';
import { InputElementProps } from '../../input-interface';

const RadioButtonGroup = ({ 
  register,
  errors,
  type,
  validationSchema,
  labelHeading,
  helper, 
  options,  
  entities,
  name,
  ...props}: InputElementProps) => {
  const { setSelectedRadioButton } = useRadioButtonStateContext();
  const [radioValue, setRadioValue] = useState(entities?.filter((i: { id: string }) => i.id === name)[0]?.value);
  setSelectedRadioButton(radioValue)

  const changeSelection = (e: any) => {
    setRadioValue(e)
    setSelectedRadioButton(e);
  }

  function renderOptions() {
    return options?.map(({ text, value }: OptionsModel, idx: number) => {
      const shortOptionLabel = text.replace(/|s+/g, '');
      const optionId = (`radio-option-${shortOptionLabel}`).toLowerCase();

      return (
        <RadioButton
          register={register}
          validationSchema={validationSchema}
          className="govuk-radios__input"
          id={`${name}-${value}`}
          name={name}
          label={text}
          key={optionId}
          type={type}
          value={value}
          aria-label={`radio-button-${value}`}
          aria-required={true}
          aria-invalid={errors[name!] ? 'true' : 'false'}
          aria-errormessage={`${name}-err`}
          role={type}
          checked={value === radioValue}
          onChange={(e) => changeSelection(e.currentTarget.value)}
        />
      );
    });
  }
  return (
    <fieldset className='govuk-fieldset govuk-!-margin-bottom-6'>
      <legend className='govuk-fieldset__legend legend__fieldset govuk-!-margin-bottom-3'>
        <b className='govuk-fieldset__legend--s govuk-!-margin-right-1'>
          {labelHeading}
        </b>
      </legend>
      <div id={`radio-group-${name}-hint`} className='govuk-radios' data-module='govuk-radios'>
        {renderOptions()}
      </div>
      <span 
        id={`${name}-err`} 
        aria-live="assertive"
        data-testid={`${name}-error-msg`} 
        className="govuk-error-message">
        {errors[name!] && errors[name!].type === 'required' &&
          'This field is required'
        }
      </span>
    </fieldset>
  );
};
export default RadioButtonGroup;