import { useContext } from 'react';
import { FormGenericStepContext } from './form-step-context';
import { RadioButtonContext } from './radio-button.context';
import { PaginationContext } from './pagination-context';

export const useFormGenericStateContext = () => ({
  ...useContext(FormGenericStepContext)
});

export const useRadioButtonStateContext = () => ({
  ...useContext(RadioButtonContext)
});

export const usePaginationStateContext = () => ({
  ...useContext(PaginationContext)
});