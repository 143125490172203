import OverviewMenu from '../components/forms/assessment/overview-menu';
import RenderForm from '../components/forms/assessment/render-form';
import SubjectlessRenderForm from '../components/forms/assessment/subjectless-render-form';
import { SubjectlessFormId } from '../constants/form-constants';
import { FormAssessmentResponse } from '../models/forms/form-assessment-response.model';
import { RootModel } from '../models/formSchema/root';
import { TenantModel } from '../models/tenants/tenant-model';

export const getStep = (
  assessment: FormAssessmentResponse,
  step: number,
  formData: RootModel,
  tenant: TenantModel,
  paragraph: string[],
) => {
  if (formData?.form.id === SubjectlessFormId) {
    return (
      <SubjectlessRenderForm
        assessment={assessment}
        schemaId={formData?.form.id}
        questions={formData?.form.questions}
        steps={formData?.form.layout.sections[step]?.steps}
        section={step}
        title={formData?.form.layout.sections[step]?.title}
        formTitle={formData?.form.name}
        sections={formData?.form.layout.sections.length}
        formData={formData}
        tenant={tenant}
      />
    );
  } else {
    if (formData?.form.layout.sections[step] !== undefined) {
      return (
        <RenderForm
          assessment={assessment}
          schemaId={formData?.form.id}
          questions={formData?.form.questions}
          steps={formData?.form.layout.sections[step]?.steps}
          section={step}
          title={formData?.form.layout.sections[step]?.title}
          formTitle={formData?.form.name}
          sections={formData?.form.layout.sections.length}
          formData={formData}
          tenant={tenant}
        />
      );
    } else {
      return (
        <OverviewMenu
          handleStep={() => -1}
          formData={formData}
          formAssessment={assessment}
          tenant={tenant}
          paragraph={paragraph}
          urn={[{ id: '', value: '' }]}
        />
      );
    }
  }
};
