import { useState } from 'react';
import { InputElementProps } from '../input-interface';
import './monetary-field.scss';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const MonetaryField = ({
  name,
  formValue,
  label,
  register,
  errors,
  required,
  type,
  helper,
  validationSchema,
  className,
  setError,
  defaultValue,
  control,
  ...props }: InputElementProps) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : formValue && formValue?.entityValues?.filter(i => i.id === name)[0]?.value);

  let monetaryfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required }
    });
    monetaryfield = field;
  }

  return (
    <>
      <label id={`${name}-hint`} className={`${className} govuk-label govuk-label--s`} htmlFor={name}>
        {label}
      </label>
      {helper &&
        <div id="amount-issued-hint" className="govuk-label">
          {helper}
        </div>
      }
      <div className="govuk-input__wrapper">
        <div className="govuk-input__prefix" aria-hidden="true">£</div>
        <input
          {...register(`${name}`, {
            required: required,
            pattern: {
              value:
                /^[0-9]*(\.[0-9]{2})?$/,
              message: 'This should be a monetary value limited to 2 decimal places'
            }
          }
          )}
          id={name}
          role='text'
          aria-label={name}
          aria-describedby={`${name}-hint`}
          aria-required={required}
          aria-invalid={errors[`${name}`] ? 'true' : 'false'}
          aria-errormessage={`${name}-err`}
          type={type}
          name={name}
          value={value}
          onChange={(e) => {
            setValue(e.currentTarget.value);
            if (monetaryfield) {
              monetaryfield.onChange(e.currentTarget.value);
            }
          }}
          maxLength={props.properties.max}
          readOnly={props.properties.readonly}
          className={`govuk-input govuk-input--width-10
            ${(!errors[name!]) && '' || errors[name!] && 'govuk-input--error'} ${props.properties.readonly ? 'readonly' : ''}`}
          {...props}
        />
      </div>
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message">
        {errors[name!] && errors[name!].type === 'required' &&
          'This field is required'
        }
        {errors[name!] && errors[name!].type === 'pattern' &&
          errors[name!]?.message
        }
      </span>
    </>
  )
}

export default MonetaryField;