import { FC } from 'react';
import './search-no-results.scss';

export type Props = {
    objectName: string;
    searchText: string;
};

const SearchNoResults: FC<Props> = ({ objectName, searchText }: Props) => {
    return (
        <div className='search-no-results'>
            {
                searchText ? <em>No {objectName} Records Found for Search Term &apos;{searchText}&apos;.</em>
                    : <em>No {objectName} Records Found.</em>
            }
        </div>
    )
}

export default SearchNoResults;