export const baseSchema = (id: string, title: string) => {
  const schema = {
    'form': {
      'id': id,
      'version': '1.0',
      'name': title,
      'layout': {
        'sections': []
      },
      'questions': []
    }
  };
  return JSON.stringify(schema, null, 2);
};