import { useEffect, useState } from 'react';
import { InputElementProps } from '../input-interface';
import './file-upload-field.scss'

const FileUploadField = ({
  name, 
  label, 
  register, 
  errors,
  required,
  type, 
  validationSchema,
  className,
  setError,
  clearErrors,
  formValue,
  setFile,
  setFilePropName,
  setValue,
  getValues,
  setFileNameToDelete,
  ...props}: InputElementProps) => {
  const [isFileForUpload, setIsFileForUpload] = useState(false);
  const [fileName, setFileName] = useState(formValue?.entityValues?.find(x => x.id == name)?.value);
  const [key, setKey] = useState(0);
  const fileSize = window.sessionStorage.getItem('fileSize');
  const fileTypes: string = props.properties && props.properties.allowedExtensions 
    ? props.properties.allowedExtensions 
    : window.sessionStorage.getItem('fileType');

  const size = parseInt(fileSize!) / 1000000;
  const requirement = (required && fileName) ? {} : validationSchema;
  
  const hasExtension = (fileName: string , exts: string) => {
    const validExtensions = exts.split(',');
    return (new RegExp('(' + validExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  const handleFileInput = (e: any) => {
    const fileUploadObj = e.target.files[0];
    const storedFileName = formValue?.entityValues?.find(x => x.id == name)?.value;

    if (storedFileName) setFileNameToDelete?.(storedFileName);

    if (e.target.files.length < 1) {
      setFile(null);
      setFileName(storedFileName);
      if (errors?.[name!]) clearErrors?.(name);
      setFileNameToDelete?.('');
      return;
    }
    setFileName(fileUploadObj.name);
    setIsFileForUpload(true);
    
    if(fileUploadObj.size == 0)
    {
      e.target.value = ''
      setError(name!, {
        type: 'filetype',
        message: 'The selected file is empty'
      });
      return;
    } else if(fileUploadObj.size > parseInt(fileSize!))
    {
      e.target.value = ''
      setError(name!, {
        type: 'filetype',
        message: `The selected file must be smaller than ${size}MB`
      })
      return;
    } else if(!hasExtension(fileUploadObj.name, fileTypes))
    {
      e.target.value = ''
      setError(name!, {
        type: 'filetype',
        message: `The selected file must be a ${fileTypes.replaceAll('.', '')
          .replaceAll(',', ', ').toUpperCase()}`
      });
      return;
    } else {
      if (errors?.[name!]) clearErrors?.(name);
      setFile(fileUploadObj);
      setFilePropName(name!);
    }
  };

  const handleRemoveFile = () => {
    const event = {
      target: {
        files: new DataTransfer().files,
        name: name,
      },
    };
    
    setKey(key + 1);
    setIsFileForUpload(false);
    setValue(name!, getValues(name!));
    handleFileInput(event as React.ChangeEvent<HTMLInputElement>);
  };

  const handleRemoveValue = () => {
    setValue(name!, '');
    setFileName('');
    setFileNameToDelete?.(formValue?.entityValues?.find(x => x.id == name)?.value ?? '');
  };

  return (
    <>
      <label id={`${name}-hint`} className={`${className} govuk-label govuk-label--s`} htmlFor={name}>
        {label}
      </label>
      <div className='fileUploadField'>
      
        {fileName != 'No file uploaded' && (
          <>
            <label id={`${name}-hint`} className={`${name} govuk-label govuk-label--s`} htmlFor={name}>
              { fileName ? fileName : '' }
            </label>
            { fileName &&
                <div className='removeFileButton' onClick={() => {
                  isFileForUpload ? handleRemoveFile() : handleRemoveValue();
                }}>
                  &times;
                </div>
            }
          </>
        )}
      </div>
      <input
        {...register(name!, requirement)}
        id={name}
        key={key}
        aria-label={name}
        aria-describedby={`${name}-hint`}
        aria-controls={name}
        aria-invalid={errors[`${name}`] ? 'true' : 'false'}
        aria-errormessage={`${name}-err`}
        type={type}
        accept={fileTypes}
        name={name}
        onChange={(e) => handleFileInput(e)}
        className={`govuk-file-upload
          ${(!errors[name!]) && '' || errors[name!] && 'govuk-input--error'}`}
        {...props}
      />
      <span 
        id={`${name}-err`} 
        aria-live="assertive"
        data-testid={`${name}-error-msg`} 
        className="govuk-error-message">
        {errors[name!] && errors[name!].type === 'required' &&
          'This field is required'
        }
        {errors[name!] && errors[name!].type === 'filetype' &&
          errors[name!].message
        }
      </span>
      <p className='govuk-!-padding-top-2'>Your file must be: </p>
      <p></p>
      <ul className='govuk-list govuk-list--bullet'>
        <li>a {fileTypes.replaceAll('.', '').replaceAll(',', ', ').toUpperCase()}</li>
        <li>smaller than {size}MB</li>
      </ul>
    </>
  );
}

export default FileUploadField;