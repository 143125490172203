import { TenantFileConfigurationModel } from '../models/tenants/tenant-file-configuration.model';
import { TenantModel } from '../models/tenants/tenant-model';
import http from './http-common';

class TenantDataService {
  getBlob(blobName: string) {
    return http.get<Blob>(`/tenants/configuration/${blobName}`, { responseType: 'blob' });
  }

  getTenantById(tenantId: string) {
    return http.get<TenantModel>(`/tenants/configuration/${tenantId}`);
  }

  create(body: TenantModel) {
    return http.post('/tenants/configuration', body);
  }

  getFileConfig(){
    return http.get<TenantFileConfigurationModel>('/tenants/configuration/file')
  }
}

export default new TenantDataService();