import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';
import AddressField from '../components/forms/elements/addressfield/addressfield';
import CheckboxField from '../components/forms/elements/checkboxes/checkbox-field';
import DateField from '../components/forms/elements/datefield/datefield';
import FileUploadField from '../components/forms/elements/file-upload/file-upload-field';
import TextField from '../components/forms/elements/inputs/textfield';
import RadioButtonField from '../components/forms/elements/radio-buttons/radio-button-field';
import DropdownField from '../components/forms/elements/selects/dropdown-field';
import TextAreaField from '../components/forms/elements/textarea/textareafield';
import { FormAssessmentResponse } from '../models/forms/form-assessment-response.model';
import { EntityModel } from '../models/formSchema/entity';
import { QuestionModel } from '../models/formSchema/question';
import MonetaryField from '../components/forms/elements/monetaryfield/monetary-field';
import LabelField from '../components/forms/elements/labelfield/labelfield';

const UseSwitch = (
  field: QuestionModel,
  register: UseFormRegister<FieldValues>,
  idx: number,
  errors: FieldErrors<FieldValues>,
  setError: UseFormSetError<FieldValues>,
  assessment: FormAssessmentResponse,
  questions: Array<QuestionModel>,
  entity: EntityModel,
  setValue: any,
  getValues: any,
  clearErrors?: UseFormClearErrors<FieldValues>,
  setFile?: any,
  setFilePropName?: any,
  suffix?: number,
  control?: Control<FieldValues, any>,
  setFileNameToDelete?: React.Dispatch<React.SetStateAction<string>>,
) => {
  switch (field.type) {
  case 'textfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`text-field-${idx}`}>
        <TextField
          register={register}
          label={field.text}
          name={field.id}
          validation={field.validation}
          helper={field.helper}
          key={`${field.id}-${idx.toString()}`}
          type="text"
          properties={field.properties}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          formValue={assessment}
          required={field.mandatory}
          errors={errors}
          setError={setError}
          control={control}
        />
      </div>
    );
  case 'monetaryfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`monetary-field-${idx}`}>
        <MonetaryField
          defaultValue={questions.find((o) => o.id === field.id)?.defaultValue}
          register={register}
          label={field.text}
          name={field.id}
          helper={field.helper}
          key={`${field.id}-${idx.toString()}`}
          type="text"
          properties={field.properties}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          formValue={assessment}
          required={field.mandatory}
          errors={errors}
          setError={setError}
          control={control}
        />
      </div>
    );
  case 'addressfield':
    return (
      <fieldset className="govuk-fieldset govuk-!-margin-bottom-6" key={`address-field-${idx}`}>
        <div className="govuk-form-group" key={`text-field-${idx}`}>
          <AddressField
            register={register}
            label={field.text}
            name={field.id}
            helper={field.helper}
            key={`${field.id}-${idx.toString()}`}
            type="text"
            properties={field.properties}
            validationSchema={
              field.mandatory
                ? {
                  required: true,
                }
                : {}
            }
            formValue={assessment}
            required={field.mandatory}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            control={control}
          />
        </div>
      </fieldset>
    );
  case 'datefield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`date-field-${idx}`}>
        <DateField
          register={register}
          labelHeading={field.text}
          type="text"
          name={field.id}
          helper={field.helper}
          key={`${field.id}-${idx.toString()}`}
          validationSchema={
            field.mandatory 
                ? {
                required: true,
                }
                : {}
          }
          properties={field.properties}
          formValue={assessment}
          required={field.mandatory}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          control={control}
        />
      </div>
    );
  case 'textareafield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`text-area-${idx}`}>
        <TextAreaField
          register={register}
          label={field.text}
          name={field.id}
          helper={field.helper}
          properties={field.properties}
          key={`${field.id}-${idx.toString()}`}
          errors={errors}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          required={field.mandatory}
          formValue={assessment}
          control={control}
        />
      </div>
    );
  case 'radiobuttonfield':
    return (
      <RadioButtonField
        labelHeading={field.text}
        register={register}
        errors={errors}
        name={field.id}
        helper={field.helper}
        key={`${field.id}-${idx.toString()}`}
        type={'radio'}
        properties={field.properties}
        options={field.options}
        validationSchema={{ required: true }}
        required={field.mandatory}
        formValue={assessment}
        conditions={entity.conditions !== null && entity.conditions}
        entities={entity.entities !== null && entity.entities}
        questions={questions}
        setError={setError}
        suffix={suffix}
        control={control}
        setValue={setValue}
        getValues={getValues}
      />
    );
  case 'checkboxfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`checkbox-${idx}`}>
        <CheckboxField
          register={register}
          clearErrors={clearErrors}
          name={field.id}
          helper={field.helper}
          key={`${field.id}-${idx.toString()}`}
          label={field.text}
          formValue={assessment}
          type="checkbox"
          properties={field.properties}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          conditions={entity.conditions !== null && entity.conditions}
          entities={entity.entities !== null && entity.entities}
          questions={questions}
          required={field.mandatory}
          errors={errors}
          setError={setError}
          suffix={suffix}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
    );
  case 'dropdownfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`select-${idx}`}>
        <DropdownField
          register={register}
          errors={errors}
          setError={setError}
          helper={field.helper}
          questions={questions}
          properties={field.properties}
          key={`${field.id}-${idx.toString()}`}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          label={field.text}
          formValue={assessment}
          conditions={entity.conditions !== null && entity.conditions}
          entities={entity.entities !== null && entity.entities}
          name={field.id}
          required={field.mandatory}
          options={field.options}
          suffix={suffix}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
    );
  case 'fileuploadfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`select-${idx}`}>
        <FileUploadField
          register={register}
          errors={errors}
          helper={field.helper}
          properties={field.properties}
          key={`${field.id}-${idx.toString()}`}
          validationSchema={
            field.mandatory
              ? {
                required: true,
              }
              : {}
          }
          type="file"
          label={field.text}
          name={field.id}
          required={field.mandatory}
          options={field.options}
          setError={setError}
          clearErrors={clearErrors}
          formValue={assessment}
          setFile={setFile}
          setFilePropName={setFilePropName}
          setValue={setValue}
          getValues={getValues}
          setFileNameToDelete={setFileNameToDelete}
        />
      </div>
    );
  case 'labelfield':
    return (
      <div className="govuk-form-group govuk-!-margin-bottom-6" key={`label-${idx}`}>
        <LabelField label={field.text} name={field.id} />
      </div>
    );
  }
};

export default UseSwitch;
