import { useState } from 'react';
import { Control, FieldValues, UseControllerReturn, UseFormRegister, useController } from 'react-hook-form';
import { Address } from '../../../../../models/address/address';

export interface IProps {
  register: UseFormRegister<FieldValues>
  errors: any,
  address?: Address
  id: string,
  control?: Control<FieldValues, any>
}

const AddressDetails = ({register, errors, address, id, control}: IProps) => {
  const [formAddress, setFormAddress] = useState<Address>(address!);
  address = formAddress;
  let addressControllers: UseControllerReturn<FieldValues, string>[];
  if (control) {
    const names = [`${id}-line_1`, `${id}-line_2`, `${id}-line_3`, `${id}-town_or_city`, `${id}-county`, `${id}-postcode`];
    const controllers = names.map(name => {
      const required = name == `${id}-line_1` || `${id}-town_or_city` || `${id}-county` || `${id}-postcode`;
      return useController({
        name: name,
        control: control,
        rules: { required: required }
      });
    });
    addressControllers = controllers;
  }
  
  return (
    <>
      <div className="govuk-form-group govuk-!-margin-bottom-5">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-line_1`}>
          Address line 1
        </label>
        <input
          {...register(`${id}-line_1`, {
            required: true
          })}
          title="Address Line 1" 
          name={`${id}-line_1`}
          id={`${id}-line_1`}
          type="text"
          aria-label="Address line 1"
          aria-required='true'
          value={formAddress?.line_1}
          onChange={(e) => {
            setFormAddress({...address!, line_1: e.target.value});
            if (addressControllers) {
              addressControllers[0].field.onChange(e.target.value);
            }
          }}
          className={`govuk-input 
          ${(!errors[`${id}-line_1`]) && '' || errors[`${id}-line_1`] && 'govuk-input--error'}`}/>
        <span 
          id={`${id}-line1-err`}
          aria-live="assertive"
          data-testid={`${id}-line_1-error-msg`}
          className="govuk-error-message">
          {errors[`${id}-line_1`] && errors[`${id}-line_1`].type === 'required' &&
            'This field is required'
          }
        </span>
      </div>
      <div className="govuk-form-group govuk-!-margin-bottom-5">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-line_2`}>
          Address line 2 (optional)
        </label>
        <input
          {...register(`${id}-line_2`, {
            required: false
          })}
          id={`${id}-line_2`}
          className="govuk-input" 
          title="Address Line 2" 
          name={`${id}-line_2`} 
          type="text"
          aria-label="Address line 2 optional"
          value={formAddress?.line_2}
          onChange={(e) => {
            setFormAddress({...address!, line_2: e.target.value});
            if (addressControllers) {
              addressControllers[1].field.onChange(e.target.value);
            }
          }}
        />
      </div>
      <div className="govuk-form-group govuk-!-margin-bottom-5">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-line_3`}>
          Address line 3 (optional)
        </label>
        <input
          {...register(`${id}-line_3`, {
            required: false
          })}
          className="govuk-input" 
          title="Address Line 3"
          id={`${id}-line_3`} 
          name={`${id}-line_3`} 
          type="text"
          aria-label="address line 3 optional"
          value={formAddress?.line_3}
          onChange={(e) => {
            setFormAddress({...address!, line_3: e.target.value});
            if (addressControllers) {
              addressControllers[2].field.onChange(e.target.value);
            }
          }}
        />
      </div>
      <div className="govuk-form-group govuk-!-margin-bottom-5">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-town_or_city`}>
          City
        </label>
        <input 
          {...register(`${id}-town_or_city`, {
            required: true
          })}
          id={`${id}-town_or_city`}
          title="City" 
          name={`${id}-town_or_city`} 
          type="text"
          aria-label="enter city"
          aria-required='true'
          value={formAddress?.town_or_city}
          onChange={(e) => {
            setFormAddress({...address!, town_or_city: e.target.value});
            if (addressControllers) {
              addressControllers[3].field.onChange(e.target.value);
            }
          }}
          className={`govuk-input govuk-!-width-two-thirds
          ${(!errors[`${id}-town_or_city`]) && '' || errors[`${id}-town_or_city`] && 'govuk-input--error'}`}/>
        <span 
          id={`${id}-townOrCity-err`}
          aria-live="assertive"
          data-testid={`${id}-townOrCity-error-msg`}
          className="govuk-error-message">
          {errors[`${id}-town_or_city`] && errors[`${id}-town_or_city`].type === 'required' &&
            'This field is required'
          }
        </span>
      </div>
      <div className="govuk-form-group govuk-!-margin-bottom-5">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-county`}>
          County
        </label>
        <input
          {...register(`${id}-county`, {
            required: false
          })}
          title="County" 
          id={`${id}-county`}
          name={`${id}-county`}
          type="text"
          aria-label="enter county"
          aria-required='true'
          value={formAddress?.county}
          onChange={(e) => {
            setFormAddress({...address!, county: e.target.value});
            if (addressControllers) {
              addressControllers[4].field.onChange(e.target.value);
            }
          }}
          className={`govuk-input govuk-!-width-two-thirds
          ${(!errors[`${id}-county`]) && '' || errors[`${id}-county`] && 'govuk-input--error'}`}/>
        <span 
          id={`${id}-county-err`}
          aria-live="assertive"
          data-testid={`${id}-county-error-msg`}
          className="govuk-error-message">
          {errors[`${id}-county`] && errors[`${id}-county`].type === 'required' &&
            'This field is required'
          }
        </span>
      </div>
      <div className="govuk-form-group">
        <label className="govuk-label govuk-label--s" htmlFor={`${id}-postcode`}>
          Postcode
        </label>
        <input
          {...register(`${id}-postcode`, {
            required: true
          })}
          id={`${id}-postcode`}
          title="Postcode" 
          name={`${id}-postcode`}
          type="text"
          aria-label="enter postcode"
          aria-required='true'
          value={formAddress?.postcode}
          onChange={(e) => {
            setFormAddress({...address!, postcode: e.target.value});
            if (addressControllers) {
              addressControllers[5].field.onChange(e.target.value);
            }
          }}
          className={`govuk-input govuk-input--width-1
          ${(!errors[`${id}-postcode`]) && '' || errors[`${id}-postcode`] && 'govuk-input--error'}`}/>
        <span 
          id={`${id}-postcode-err`}
          aria-live="assertive"
          data-testid={`${id}-postcode-error-msg`}
          className="govuk-error-message">
          {errors[`${id}-postcode`] && errors[`${id}-postcode`].type === 'required' &&
            'This field is required'
          }
        </span>
      </div>
    </>
  )
}

export default AddressDetails;