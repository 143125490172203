import { useState } from 'react';
import { ISelectElementProps } from '../input-interface';
import ConditionalQuestion from '../conditions/conditional-questions';
import './dropdown-field.scss';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const DropdownField = ({
  register,
  errors,
  formValue,
  required,
  validationSchema,
  setError,
  clearErrors,
  label,
  helper,
  name,
  entities,
  conditions,
  questions,
  options,
  suffix,
  control,
  setValue,
  getValues,
  ...props }: ISelectElementProps) => {
  let dropdownfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required }
    });
    dropdownfield = field;
  }
  
  const [selectedValue, setSelectedValue] = useState(
    formValue && formValue?.entityValues?.filter((i) => i.id === name)[0]?.value
  );
  const [showCondition, setShowCondition] = useState(false);
  const hasConditions = conditions != null && conditions.length;

  const handleChange = (e: string, value: any) => {
    setSelectedValue(e);

    if (hasConditions) {
      setShowCondition(e == value)
    }
  }

  const selectOption = options.map((field, idx) => (
    <option
      key={idx}
      value={field.value}
    >
      {field.text}
    </option>
  ));

  return (
    <div className="govuk-form-group" aria-labelledby={`${name}-hint`}>
      <label
        id={`${name}-hint`}
        className="govuk-label govuk-label--s"
        htmlFor={`${name}-title`} >
        {label}
      </label>
      {helper &&
        <div id="dropdown-area-hint" className="govuk-label">
          {helper}
        </div>
      }
      <select
        {...register(name!, validationSchema)}
        id={`${name}-title`}
        title='title'
        className="govuk-select"
        name={name}
        role='listbox'
        aria-label={name}
        aria-describedby={`${name}-hint`}
        aria-required={required ? true : false}
        aria-invalid={errors[name!] ? 'true' : 'false'}
        aria-errormessage={`${name}-err`}
        aria-orientation='vertical'
        value={selectedValue}
        onChange={(e) => {
          handleChange(e.target.value, conditions !== undefined && conditions[0]?.value);
          if (dropdownfield) {
            dropdownfield.onChange(e.currentTarget.value);
          }
        }}
      >
        <option value='' aria-label='please select one from dropdown'>--Please Select--</option>
        {selectOption}
      </select>
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message">
        {errors[name!] && errors[name!]?.type === 'required' &&
          'This field is required'
        }
        {errors[name!] && errors[name!].type === 'pattern' &&
          errors[name!]?.message
        }
      </span>
      {(showCondition || selectedValue === String(conditions !== undefined && conditions[0]?.value)) &&
        <ConditionalQuestion
          setError={setError}
          register={register}
          errors={errors}
          formValue={formValue!}
          questions={questions}
          entities={entities}
          clearErrors={clearErrors}
          suffix={suffix}
          setValue={setValue}
          getValues={getValues}
        />
      }
    </div>
  )
}

export default DropdownField;