import { FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetError } from 'react-hook-form';
import UseSwitch from '../../../../hooks/use-switch';
import { FormAssessmentResponse } from '../../../../models/forms/form-assessment-response.model';
import { EntityModel } from '../../../../models/formSchema/entity';
import { QuestionModel } from '../../../../models/formSchema/question';

export interface IProps {
  formValue: FormAssessmentResponse;
  questions: Array<QuestionModel>;
  entities: Array<EntityModel>;
  setError: UseFormSetError<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: any;
  clearErrors?: UseFormClearErrors<FieldValues>;
  suffix?: number;
  setValue: any,
  getValues: any,
}

const ConditionalQuestion = ({ formValue, questions, entities, setError, 
  register, errors, clearErrors, suffix, setValue, getValues}: IProps) => {

  const renderConditions = entities?.map((res: any, idx: number) => {
    return questions
      ?.filter((i: { id: string }) => i.id === res.id)
      .map((field: any) => {
        const f: QuestionModel = {
          id: suffix == undefined || suffix === 1 ? field.id : `${field.id}${suffix?.toString()}`,
          text: field.text,
          type: field.type,
          mandatory: field.mandatory,
          properties: field.properties,
          options: field.options,
          helper: field.helper,
          validation: field.validation
        }
        return UseSwitch(
          f, 
          register, 
          idx, 
          errors, 
          setError, 
          formValue, 
          questions, 
          res, 
          setValue, 
          getValues, 
          clearErrors
        );
      });
  });

  return (
    <div className="govuk-radios__conditional" id="conditional-contact">
      {renderConditions}
    </div>
  );
};

export default ConditionalQuestion;
