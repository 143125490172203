import { ReactNode } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, tokenRequest } from '../authConfig';

const ProtectedRoute = (props: {children: ReactNode}): JSX.Element => {
  const {children} = props;

  const LoadingComponent = () => {
    return (
      <div id='assessment' className='govuk-width-container govuk-!-margin-top-6'>
        <main className='govuk-main-wrapper main__container' id='main-content' role='main'>
          <p>Authentication in progress...</p>
        </main>
      </div>
    );
  }

  const errorComponent = (e: any) => {
    return <div id='assessment' className='govuk-width-container govuk-!-margin-top-6'>
      <main className='govuk-main-wrapper main__container' id='main-content' role='main'>
        <p>An Error Occurred: {e ? 'Access denied, you are not authorized to view this page' : 'unknown error'}</p>
      </main>
    </div>
  }

  const authRequest = {
    ...loginRequest,
    ...tokenRequest
  };
  
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect} 
      authenticationRequest={authRequest}
      loadingComponent={LoadingComponent}
      errorComponent={errorComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  )
}

export default ProtectedRoute;