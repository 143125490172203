import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../../models/users/user.model';
import UserDataService from '../../services/user.service';
import { UsersPaginated } from '../../models/users/users-paginated.model';

export const createUser = createAsyncThunk(
  'users/create',
  async ({tenantId, userCreateObj}: {tenantId: string, userCreateObj: UserModel}, thunkApi) => {
    try {
      const response = await UserDataService.create(tenantId, userCreateObj);
      response.status
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  'users/getAll',
  async ({pageSize, page, searchText}: {pageSize: number, page: number, searchText?: string}, thunkApi) => {
    try {
      const response = await UserDataService.getAll(pageSize, page, searchText);
      return response.data;
    } catch (err: any) {      
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);


export interface UserState {
  loading: boolean;
  error: string | null;
  users: UsersPaginated | object
}

const initialState = {
  loading: false,
  error: null,
  users: {},
} as UserState

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.users = {};
      });
  }
});

const { reducer } = userSlice;
export default reducer;