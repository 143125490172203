import { FormLookup } from '../models/forms/form-lookup.model';
import http from './http-common';

class FormLookupService {
  getForm(schemaId: string) {
    return http.get<FormLookup>(`/formtemplates/${schemaId}`, {
      params: {
        schemaId: schemaId,
      },
    });
  }
}

export default new FormLookupService();
