import { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/debounce';
import { Address } from '../../../../models/address/address';
import { Suggestion } from '../../../../models/address/suggestion';
import { InputElementProps } from '../input-interface';
import AddressDetails from './address-details/address-details';

const addressApi = window.REACT_APP_GET_ADDRESS_API;
const apiKey = window.REACT_APP_GET_ADDRESS_API_KEY;

import './addressfield.scss';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const AddressField = ({
  name,
  formValue,
  label,
  register,
  errors,
  required,
  type,
  validationSchema,
  className,
  control,
  clearErrors,
  ...props
}: InputElementProps) => {
  let addressfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required },
    });
    addressfield = field;
  }

  const address = {
    line_1: formValue?.entityValues?.filter((i) => i.id === `${name}-line_1`)[0]?.value as string,
    line_2: formValue?.entityValues?.filter((i) => i.id === `${name}-line_2`)[0]?.value as string,
    line_3: formValue?.entityValues?.filter((i) => i.id === `${name}-line_3`)[0]?.value as string,
    town_or_city: formValue?.entityValues?.filter((i) => i.id === `${name}-town_or_city`)[0]
      ?.value as string,
    county: formValue?.entityValues?.filter((i) => i.id === `${name}-county`)[0]?.value as string,
    postcode: formValue?.entityValues?.filter((i) => i.id === `${name}-postcode`)[0]
      ?.value as string,
  };

  const [showAutoComplete, setShowAutoComplete] = useState(true);
  const [showAddressesOnSelect, setShowAddressesOnSelect] = useState<boolean>();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [addressObj, setAddressObj] = useState<Address>(address);
  const [searchAddress, setSearchAddress] = useState(
    formValue && formValue?.entityValues?.filter((i) => i.id === name)[0]?.value,
  );
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const debounceSearchAddress = useDebounce(searchAddress, 500);
  const [addressHasValue, setAddressHasValue] = useState(
    formValue && formValue?.entityValues?.filter((i) => i.id === `${name}-line_1`)[0]?.value
      ? true
      : false,
  );

  useEffect(() => {
    const apiGetSuggestions = async () => {
      setLoading(true);
      setSuggestions([]);

      const getSuggestions = await fetch(
        `${addressApi}/autocomplete/${debounceSearchAddress}?api-key=${apiKey}&all=true`,
        { method: 'GET' },
      ).then((res) => res.json());
      setSuggestions(getSuggestions.suggestions);
      setLoading(false);
      setIsComponentVisible(true);
    };

    if (debounceSearchAddress) apiGetSuggestions();
  }, [debounceSearchAddress, isComponentVisible]);

  const apiGetAddress = async (url: string) => {
    const getAddress = await fetch(`${addressApi}/${url}?api-key=${apiKey}`, {
      method: 'GET',
    }).then((res) => res.json());

    setAddressObj(getAddress);
    setShowAutoComplete(false);
    setShowAddressesOnSelect(true);
    clearErrors?.(name);
  };

  const handleKey = (event: React.KeyboardEvent<HTMLDivElement>, value: Suggestion): void => {
    if (event.key === 'Enter') {
      setSearchAddress(value.address);
      setSuggestions([]);
      apiGetAddress(value.url);
    }
  };

  const handleClick = (value: Suggestion) => {
    setSearchAddress(value.address);
    setSuggestions([]);
    apiGetAddress(value.url);
  };

  const refindAddress = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setAddressHasValue(false);
    setIsComponentVisible(false);
    setSearchAddress(addressObj.line_1);
    setShowAutoComplete(true);
    setShowAddressesOnSelect(false);
  };

  const findAddressManually = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setAddressHasValue(addressHasValue);
    setAddressObj(addressObj);
    setShowAutoComplete(false);
    setShowAddressesOnSelect(true);
  };

  const validate = () => {
    if (showAddressesOnSelect) return true;

    if (!addressHasValue) return false;

    return true;
  };

  return (
    <div id="address__lookup">
      <div className="address__lookup">
        <div className="flex-left">
          <legend id={`${name}-hint`} className={`${className} govuk-label govuk-label--s`}>
            {label}
          </legend>
        </div>
        <div className="flex-right">
          {showAutoComplete && !addressHasValue ? (
            <button
              data-module="govuk-button"
              className="govuk-button govuk-heading-s govuk-!-margin-bottom-3"
              onClick={findAddressManually}
            >
              Enter address manually
            </button>
          ) : (
            <button
              data-module="govuk-button"
              className="govuk-button govuk-heading-s"
              onClick={refindAddress}
            >
              Find address by postcode
            </button>
          )}
        </div>
      </div>
      <>
        <div className="address__lookup">
          <input
            {...register(`${name}`, { required: required, validate: { validate } })}
            id="address"
            autoComplete="off"
            role="text"
            aria-label={name}
            aria-describedby={`${name}-hint`}
            aria-required={showAddressesOnSelect && !addressHasValue ? false : true}
            aria-invalid={errors[`${name}`] ? 'true' : 'false'}
            aria-errormessage={`${name}-err`}
            type={showAutoComplete && !addressHasValue ? 'text' : 'hidden'}
            name={name}
            value={searchAddress}
            placeholder="Start typing your address to search"
            onChange={(e) => {
              setSearchAddress(e.target.value);
              if (addressfield) {
                addressfield.onChange(e.target.value);
              }
            }}
            className={`govuk-input 
              ${(!errors[name!] && '') || (errors[name!] && 'govuk-input--error')}`}
            {...props}
          />
        </div>
        {loading && <p className="address__loading">Loading...</p>}
        <>
          <div
            className="govuk-panel"
            style={{
              display:
                showAutoComplete && isComponentVisible && !addressHasValue ? 'block' : 'none',
            }}
          >
            <div className="govuk-panel__body address__panel">
              {suggestions.map((suggestion: Suggestion) => (
                <div
                  key={suggestion.id}
                  tabIndex={0}
                  className="address__panel-list"
                  aria-label={suggestion.address}
                  onClick={() => handleClick(suggestion)}
                  onKeyDown={(e) => handleKey(e, suggestion)}
                >
                  {suggestion.address}
                </div>
              ))}
            </div>
          </div>
        </>
        <span
          id={`${name}-err`}
          aria-live="assertive"
          data-testid={`${name}-error-msg`}
          className="govuk-error-message"
        >
          {errors[name!] && errors[name!].type === 'required' && 'This field is required'}
          {errors[name!] && errors[name!].type === 'pattern' && errors[name!]?.message}
          {errors[name!] && errors[name!].type === 'validate' && 'Select an address from list'}
        </span>
      </>
      {((showAddressesOnSelect && !addressHasValue) ||
        (!showAddressesOnSelect && addressHasValue)) && (
        <>
          {
            <AddressDetails
              register={register}
              errors={errors}
              address={addressObj}
              id={name!}
              control={control}
            />
          }
        </>
      )}
    </div>
  );
};

export default AddressField;
