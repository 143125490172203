import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';
import { Provider } from 'react-redux';
import store from './store/store';
import { HelmetProvider } from 'react-helmet-async';

loginRequest.state = window.location.pathname;
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <App msalInstance={msalInstance} />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>  
  </HelmetProvider>
);

reportWebVitals();
