import { useState } from 'react';

const UseModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => setIsOpen(!isOpen);
  
  return {
    isOpen,
    handleClose
  }
}

export default UseModal;