import { useState } from 'react';
import ConditionalQuestion from '../conditions/conditional-questions';
import { InputElementProps } from '../input-interface';
import './checkbox-field.scss';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const CheckboxField = ({
  name,
  formValue,
  label,
  register,
  clearErrors,
  errors,
  helper,
  required,
  type,
  validationSchema,
  className,
  conditions,
  entities,
  questions,
  setError,
  suffix,
  control,
  setValue,
  getValues,
  ...props }: InputElementProps) => {
  let checkboxfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required }
    });
    checkboxfield = field;
  }
  
  const [isChecked, setIsChecked] = useState<string | undefined>(
    formValue && formValue?.entityValues?.filter((i) => i.id === name)[0]?.value);
  const [showCondition, setShowCondition] = useState(false);
  const hasConditions = conditions != null && conditions.length;

  const handleChange = (e: any, value: any) => {
    setIsChecked(String(e));
    if (hasConditions)
      setShowCondition(e == value);
  };

  return (
    <>
      <div className="govuk-checkboxes" data-module="govuk-checkboxes">
        <div className="govuk-checkboxes__item" key={`checkbox-field-${name}`}>
          <input
            {...register(name!, validationSchema)}
            title={label}
            className="govuk-checkboxes__input"
            id={name}
            name={name}
            type={type}
            value={isChecked}
            key={`${name}-key`}
            role="checkbox"
            aria-label={name}
            aria-describedby={`${name}-hint`}
            aria-required={required ? true : false}
            aria-invalid={errors[name!] ? 'true' : 'false'}
            aria-errormessage={`${name}-err`}
            checked={isChecked === 'true'}
            onChange={(e) => {
              handleChange(e.target.checked, conditions !== undefined && conditions[0]?.value);
              if (checkboxfield) {
                checkboxfield.onChange(e.currentTarget.value);
              }
            }}
            {...props}
          />
          <label
            id={`${name}-hint`}
            aria-label={label}
            className="govuk-label govuk-checkboxes__label"
            htmlFor={name}
          >
            {label}
          </label>
          {helper &&
            <div id="checkbox-checked-hint" className="govuk-label">
              {helper}
            </div>
          }
        </div>
      </div>
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message"
      >
        {isChecked !== 'true' &&
          errors[name!] &&
          errors[name!]?.type === 'required' &&
          'This field is required'}
        {errors[name!] && errors[name!]?.type === 'pattern' && errors[name!]?.message}
      </span>
      {(showCondition || isChecked === String(conditions !== undefined && conditions[0]?.value)) &&
        <ConditionalQuestion
          setError={setError}
          register={register}
          errors={errors}
          formValue={formValue!}
          questions={questions}
          entities={entities}
          clearErrors={clearErrors}
          suffix={suffix}
          setValue={setValue}
          getValues={getValues}
        />
      }
    </>
  );
};

export default CheckboxField;
