import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import './hello-user-icon.scss';

type Props = {
  firstName: string | undefined;
  lastName: string | undefined;
  isCitizen: boolean;
};

function HelloUserIcon({ firstName, lastName, isCitizen }: Props) {
  const mobileView = window.innerWidth < 500 ? true : false;

  return !mobileView ? (
    <>
      { isCitizen ? (
        <>
          <UserSwitchOutlined className="user_icon" />
          {'Acting on behalf of:'}<br /><div className='acting_on_name'>
            {`${firstName} ${lastName}`}</div>
        </>
      ) : (
        <>
          <UserOutlined className="user_icon" />
          {`Hello, ${firstName} ${lastName}`}
        </>
      )}
    </>
  ) : (
    <div className="header_mobile_view">
      <>
        {  isCitizen ? (
          <>
            <UserSwitchOutlined className="user_icon" />
            {'Acting on behalf of:'}<br /><div className='acting_on_name'>
              {`${firstName} ${lastName}`}</div>
          </>
        ) : (
          <>
            <UserOutlined className="user_icon" />
            {`Hello, ${firstName} ${lastName}`}
          </>
        )}
      </>
    </div>
  );
}

export default HelloUserIcon;
