export const tenantConfig = {
  id: '',
  name: '',
  tenantLogo: {logo: null, blobName: ''},
  carouselSlides: [{
    image: null, 
    blobName: '', 
    imageAlt: '', 
    caption: '', 
    linkBtn: '', 
    textBtn: ''
  }],
  tenantCss: {file: null, blobName: ''},
  contactEmail: '',
  contactNumber: '',
  message: '',
  cacheDuration: 3600,
  deleteMaliciousFiles: false
}