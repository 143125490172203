import { FC } from 'react';

export interface FileExistsProps {
  onUploadCopy: () => void;
  onUploadAndReplace: () => void;
  message: string;
}

const FileExistsModal: FC<FileExistsProps> = (props) => {
  return (
    <>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <div className="govuk-warning-text">
            <span className="govuk-warning-text__icon" aria-hidden="true">!</span>
            <strong className="govuk-warning-text__text">
              {props.message}
            </strong>
          </div>
        </div>
      </div>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <button
            id="leave-button"
            className="govuk-button govuk-button--secondary 
            govuk-!-padding-left-4 govuk-!-padding-right-4 govuk-!-margin-right-4"
            data-module="govuk-button"
            aria-label="Upload Copy"
            onClick={props.onUploadCopy}>
            Upload <br></br> Copy
          </button>
          <button
            id="save-button"
            className="govuk-button govuk-!-padding-left-4 govuk-!-padding-right-4"
            data-module="govuk-button"
            aria-label="Upload and Replace"
            onClick={props.onUploadAndReplace}>
            Upload and Replace
          </button>
        </div>
      </div>
    </>
  )
}

export default FileExistsModal;