import { IButtonElementProps } from '../input-interface';

const Button = ({label, icon, iconPosition, id, secondaryClass, ...props}: IButtonElementProps) => {
  const shortLabel = label.replace(/\s+/g, '');
  const optionId = (`button-${shortLabel}`.toLowerCase());
  const secondaryClassName = secondaryClass !== undefined ? secondaryClass : '';

  return (
    <button
      id={id}
      className={`govuk-button ${secondaryClassName}`}
      data-module="govuk-button" 
      role='button'
      aria-label={optionId}
      aria-describedby={`${optionId}-hint`}
      {...props}>
      {!iconPosition && label}
      {iconPosition === 'right' && label} 
      <div id={`${optionId}-hint`} className='govuk-button__start-icon'>
        {icon}
      </div>
      {iconPosition === 'left' && label}
    </button>
  )
}

export default Button;