import { EndSessionRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { FC, useEffect, useRef, useState } from 'react';
import sessionService from '../../services/session.service';
import { setSelectedCitizen } from '../../slices/sessions/session-slice';
import { Role } from '../../Enums/Role';
import './side-drawer-menu.scss';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { CitizenModel } from '../../models/citizen/CitizenModel';
import { DownOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { MenuProps, Space, Menu, Drawer, Button } from 'antd';
import HelloUserIcon from './hello-user-icon';
import { useNavigate } from 'react-router-dom';
import { getAllCitizens } from '../../slices/citizens/citizen-slice';
import { useSelector } from 'react-redux';
import UserDataService from '../../services/user.service';

export type Props = {
  accounts: any;
  tenantName: string;
};

const SideDrawerMenu: FC<Props> = ({ accounts, tenantName }: Props) => {
  const dispatch = useAppDispatch();
  const citizenStatus = useSelector((state: any) => state.citizens.status);
  const { instance } = useMsal();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const currentAccount = instance.getAccountByHomeId(accounts[0]?.homeAccountId);
  const currentSession = sessionService.session?.session || null;
  const navigate = useNavigate();
  const selectedCitizenId = useSelector(
    (state: any) => state.sessions.session?.citizenSession?.citizenId,
  );
  const [yourCitizen, setYourCitizen] = useState<CitizenModel>({
    firstName: '',
    lastName: '',
    isEnabled: true,
  });

  const citizens = useSelector((state: any) => state.citizens.entities);

  const [drawerClosed, setDrawerClosed] = useState(true);

  const showDrawer = () => {
    setOpen(true);
    setDrawerClosed(false);
  };

  const onClose = () => {
    setOpen(false);
    setDrawerClosed(true);
  };

  onresize = () => {
    setOpen(false);
    setDrawerClosed(true);
  };

  const logout = () => {
    try {
      sessionService
        .signOut()
        .then((_) => {
          const endSessionRequest: EndSessionRequest = {
            account: currentAccount,
            postLogoutRedirectUri: `/${tenantName}/welcome`,
          };
          instance.logoutRedirect(endSessionRequest);
        })
        .then(() => {
          sessionService.clear();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getCitizens = async () => {
    await dispatch(getAllCitizens()).unwrap();
  };

  useEffect(() => {
    if (citizenStatus !== 'idle') {
      return;
    }
    getCitizens();
  }, [accounts[0].localAccountId, dispatch, citizenStatus]);

  const fetchName = async () => {
    try {
      const { data } = await UserDataService.get(accounts[0].localAccountId as string);

      if (data) {
        setYourCitizen(prev => ({
          ...prev,
          firstName: data.firstName,
          lastName: `${data.lastName} (You)`,
        }))
      } 
    } catch (e) {
      setYourCitizen(prev => ({
        ...prev,
        firstName: accounts[0]?.idTokenClaims?.given_name,
        lastName: `${accounts[0]?.idTokenClaims?.family_name} (You)`,
      }))
      console.log(e)
    }
  }

  useEffect(() => {
    fetchName();
  }, [])

  const switchUser = async (citizen: CitizenModel) => {
    setOpen(false);
    await dispatch(setSelectedCitizen(citizen.internalCitizenId)).unwrap()
    const path = `/${tenantName}/dashboard`;
    navigate(path);
    setOpen(false);
  };

  const handleUserManagementClick = () => {
    navigate(`/${tenantName}/user-management`);
  };

  const handleFormManagementClick = () => {
    navigate(`/${tenantName}/form-management`);
  };

  const handleCareInformationClick = () => {
    navigate(`/${tenantName}/care-information`);
  };

  const handleHomeClick = () => {
    navigate(`/${tenantName}/dashboard`);
  };

  const profileSubMenuItems: MenuProps['items'] = Array.isArray(citizens)
    ? [yourCitizen, ...citizens].map((citizen, index) => ({
      key: `citizen-${index}`,
      icon:
          selectedCitizenId == citizen.internalCitizenId ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" className="checkmark_icon" />
          ) : (
            ''
          ),
      label: `${citizen.firstName} ${citizen.lastName}`,
      style: {
        fontWeight: selectedCitizenId == citizen.internalCitizenId ? 'bold' : 'normal',
        background: selectedCitizenId == citizen.internalCitizenId ? '#F5F5F5' : '',
        display: citizen.isEnabled ? 'flex' : 'none',
        height: 'auto',
      },
      className: 'profile_label',
      onClick: () => switchUser(citizen),
    }))
    : [];

  const SubMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Home',
      style: { marginBottom: '2.25rem' },
      onClick: () => handleHomeClick(),
      className: 'menu_drawer_item',
    },

    ...(currentSession?.roles?.find(
      (role) => role == Role[Role.Administrator] && !currentSession?.citizenSession?.citizenId,
    )
      ? [
        {
          key: '2',
          label: 'User Management',
          style: { marginBottom: '2.25rem' },
          onClick: () => handleUserManagementClick(),
          className: 'menu_drawer_item',
        },
        {
          key: '3',
          label: 'Form Management',
          style: { marginBottom: '2.25rem' },
          onClick: () => handleFormManagementClick(),
          className: 'menu_drawer_item',
        },
      ]
      : []),
    {
      key: '4',
      label: 'Care Information',
      style: { marginBottom: '2.25rem' },
      onClick: () => handleCareInformationClick(),
      className: 'menu_drawer_item',
    },
    ...(citizens.length > 0
      ? [
        {
          key: '5',
          label: 'Select Profile',
          style: { marginBottom: '2.25rem' },
          children: profileSubMenuItems,
          className: 'menu_drawer_item',
        },
      ]
      : [])
  ];

  const currentPage = window.location.href;
  const yourCitizenDisplayText = yourCitizen.firstName.length > 0 
    ? `Hello,  ${yourCitizen.firstName} ${yourCitizen.lastName.replace('(You)', '')}` 
    : ''

  return currentPage.includes('welcome') ? (
    <div />
  ) : (
    <div ref={dropdownRef} className="dropdown_menu">
      <Button onClick={showDrawer} tabIndex={0} role="button" className="menu_button" type="text">
        <Space>
          {!selectedCitizenId
            ? yourCitizenDisplayText
            : `Acting on behalf of: ${sessionService.session?.session?.citizenSession?.firstName} 
              ${sessionService.session?.session?.citizenSession?.lastName}`}
          {drawerClosed ? (
            <DownOutlined style={{ transition: '0.5s', fontWeight: 'lighter' }} />
          ) : (
            <DownOutlined
              style={{
                transform: `rotate(${window.innerWidth < 500 ? 0.25 : -0.25}turn)`,
                transition: '0.5s',
                fontWeight: 'bolder',
              }}
            />
          )}
        </Space>
      </Button>

      <Drawer
        aria-label="menu-drawer"
        className="menu_drawer"
        placement={window.innerWidth < 500 ? 'left' : 'right'}
        width={
          window.innerWidth > 1400
            ? '25%'
            : window.innerWidth > 1000
              ? '30%'
              : window.innerWidth < 400
                ? '80%'
                : '60%'
        }
        onClose={onClose}
        open={open}
        closeIcon={window.innerWidth < 500 ? false : false}
        title={
          <HelloUserIcon
            firstName={
              selectedCitizenId == null
                ? yourCitizen.firstName
                : sessionService.session?.session?.citizenSession?.firstName
            }
            lastName={
              selectedCitizenId == null
                ? yourCitizen.lastName
                : sessionService.session?.session?.citizenSession?.lastName
            }
            isCitizen={selectedCitizenId != null}
          />
        }
        footer={
          <div className="logout_area" onClick={logout}>
            <button type="submit" className="govuk-button header_sign_out">
              Sign Out
            </button>
          </div>
        }
      >
        <Menu
          style={{}}
          mode="inline"
          items={SubMenuItems}
          tabIndex={0}
          role="button"
          aria-label="menu"
        />
      </Drawer>
    </div>
  );
};

export default SideDrawerMenu;
