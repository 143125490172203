import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionService from '../../services/session.service';
import { SessionCreateModel } from '../../models/session/session-create';
import { SessionResponse } from '../../models/session/session-response';

export const createSession = createAsyncThunk(
  'sessions/Create', 
  async (model: {payload: SessionCreateModel, tenantName: string} , thunkApi) => {
    try {
      const response = await SessionService.create(model.payload);
      if (response.status != 200) {
        throw {
          reason: response.statusText,
          correlationId: response.headers['correlationId'],
        };
      }

      SessionService.session = {
        session: response.data, 
        tenantId: model.payload.tenantId,
        tenant: model.tenantName
      };
      return SessionService.session;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const setSelectedCitizen = createAsyncThunk(
  'sessions/selectCitizen', 
  async (citizenId: string | undefined, thunkApi) => {
    try {
      const response = citizenId === '' || !citizenId
        ? await SessionService.clearCitizenSession()
        : await SessionService.updateCitizenSession(citizenId);
      SessionService.session = {
        session: response.data,
        tenantId: SessionService?.session?.tenantId ?? '',
        tenant: SessionService?.session?.tenant ?? '',
      };
      return SessionService.session;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export interface SessionsState {
  status: 'idle'| 'loading' | 'failed' | 'success';
  error: string | null;
  session: SessionResponse | null;
  tenantId: string | null;
  tenantName: string | null;
}

const initialState = {
  status: 'idle',
  error: null,
  session: SessionService.session?.session || null,
  tenantId: null,
  tenantName: null
} as SessionsState

const sessionsSlice = createSlice({
  name: 'Sessions',
  initialState,
  reducers: {
    clear: (state) => {
      SessionService.clear();
      state.session = null;
      state.tenantId = null;
      state.tenantName = null;
      state.status = 'idle';
    }
  },
    
  extraReducers(builder) {
    builder
      .addCase(createSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSession.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'success';
        state.session = action.payload.session;
        state.tenantId = action.payload.tenantId;
        state.tenantName = action.payload.tenant;
      })
      .addCase(createSession.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
        state.session = null;
      })
      .addCase(setSelectedCitizen.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setSelectedCitizen.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'success';
        state.session = action.payload.session;
        state.tenantId = action.payload.tenantId;
        state.tenantName = action.payload.tenant;
      })
      .addCase(setSelectedCitizen.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
        state.session = null;
      });
  },
});

const { reducer } = sessionsSlice;
export default reducer;