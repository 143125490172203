import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import CitizenService from '../../services/citizen.service';
import { CitizenModel } from '../../models/citizen/CitizenModel';

export const getAllCitizens = createAsyncThunk(
  'citizens/getAll', 
  async (_ , thunkApi) => {
    try {
      const response = await CitizenService.getAll();
      return response.data
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export interface CitizenState {
  status: 'idle'| 'loading' | 'failed' | 'success';
  error: string | null;
  entities: CitizenModel[] | null
}

const initialState = {
  status: 'idle',
  error: null,
  entities: []
} as CitizenState

const getCitizensSlice = createSlice({
  name: 'Citizens',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCitizens.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCitizens.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'success';
        if (action.payload) {
          state.entities = action.payload.filter((citizen: CitizenModel) => citizen.isEnabled === true);
        } else {
          return
        }
      })
      .addCase(getAllCitizens.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

const { reducer } = getCitizensSlice;
export default reducer;