import { createContext } from 'react';

export const FormGenericStepContext = createContext({
  step: 0,
  setStep: (value: number) => {},
  summary: false,
  setSummary: (value: boolean) => {},
  conditionalStep: 0,
  setConditionalStep: (value: number) => {},
  urn: [{id: '', value: ''}],
  setUrn: (value: any[]) => {},
  fileUploadObj: undefined,
  setFile: (value: any) => {},
  name: '',
  setFilePropName: (value: string) => {},
  submitData: null,
  setSubmitData: (value: any) => {}
});