import { useState } from 'react';
import { TextAreaFieldElementProps } from '../input-interface';

import './textareafield.scss'
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const TextAreaField = ({
  rows=4, 
  cols=50, 
  label, 
  name, 
  className,
  helper,
  register, 
  errors,
  required,
  validationSchema,
  formValue,
  control,
  ...props}: TextAreaFieldElementProps) => 
{
  let textareafield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required }
    });
    textareafield = field;
  }
  
  const [value, setValue] = useState(formValue && formValue?.entityValues?.filter(i => i.id === name)[0]?.value);
  const [characterCount, setCharacterCount] = useState(value ? value.length : 0);

  return (
    <>
      <label
        id={`${name}-hint`}
        className={`${className} govuk-label govuk-label--s`}
        htmlFor={name}
      >
        {label}
      </label>
      {helper && (
        <div id="text-area-hint" className="govuk-label">
          {helper}
        </div>
      )}
      <textarea
        {...register(name, validationSchema)}
        id={name}
        name={name}
        rows={rows}
        cols={cols}
        role="textbox"
        value={value}
        aria-label={name}
        aria-describedby={`${name}-hint`}
        aria-required={required ? true : false}
        aria-invalid={errors[name] ? 'true' : 'false'}
        aria-errormessage={`${name}-err`}
        maxLength={props.properties.max}
        {...props}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          setCharacterCount(e.currentTarget.value.length);
          if (textareafield) {
            textareafield.onChange(e.currentTarget.value);
          }
        }}
        className={`govuk-textarea ${
          (!errors[name] && '') || (errors[name] && 'govuk-textarea--error')
        }`}
      ></textarea>
      <div className="error-container">
        <span
          id={`${name}-err`}
          aria-live="assertive"
          data-testid={`${name}-error-msg`}
          className="govuk-error-message"
        >
          {errors[name] && errors[name].type === 'required' && 'This field is required'}
          {errors[name] && errors[name].type === 'pattern' && errors[name].message}
        </span>
        <p data-testid="error-msg" className="characters__count">
          {`${characterCount}/${props.properties.max}`}
        </p>
      </div>
    </>
  );
}

export default TextAreaField;