import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormListModel } from '../../models/forms/form-list.model';
import {
  SelfAssessmentFormId as selfAssessmentId,
  FinancialAssessmentFormId as financialAssessmentId,
  UserRegistrationFormId as userRegistrationId,
  SubmitAdditionalInformation as submitAdditionalInformation,
  RequestingProxyAccess as requestingProxyAccess,
  RemovingProxyAccess as removingProxyAccess,
  GivingProxyAccess as givingProxyAccess,
  ChangeInPersonalDetails as changeInPersonalDetails,
} from '../../constants/form-constants';
import './form-search-results-table.scss';
import FormTemplateService from '../../services/formTemplate.service';
import FormManagementService from '../../services/form-management.service';
import Pagination from '../pagination/pagination';
import { PaginationContext } from '../../context/pagination-context';
import { FormsManagement } from '../../models/forms/form-management.model';

export type Props = {
  tenantName: string;
  tableId: string;
  page: number;
  totalPages: number;
  setPage: any;
  tableHeaders: Array<string>;
  tableCells: FormListModel[] | null;
  searchResultsRecordCount: number;
  totalRecordCount: number;
  setFormList: (list: FormsManagement) => void;
};

const FormSearchResultsTable: FC<Props> = ({
  tenantName,
  tableId,
  tableHeaders,
  tableCells,
  page,
  totalPages,
  setPage,
  totalRecordCount,
  searchResultsRecordCount,
  setFormList,
}: Props) => {
  const [disabledFormsList, setDisabledFormsList] = useState<Array<string>>();

  const getDisabledFormSchemas = () => {
    let disabledForms: Array<string> = [];

    const schemaIdsToInclude = new Set([
      userRegistrationId,
      financialAssessmentId,
      selfAssessmentId,
      submitAdditionalInformation,
      requestingProxyAccess,
      removingProxyAccess,
      givingProxyAccess,
      changeInPersonalDetails,
    ]);

    if (tableCells)
      disabledForms = tableCells
        .filter((form) => schemaIdsToInclude.has(form.schemaId))
        .map((form) => form.schemaId);

    setDisabledFormsList(disabledForms);
  };

  useEffect(() => {
    getDisabledFormSchemas();
  }, [tableCells]);

  const checkDisabled = (id: string) => {
    return disabledFormsList?.includes(id);
  };

  const removeRow = (id: string) => {
    const list = tableCells!.filter((item) => item.schemaId != id);
    const newTotalPages = Math.ceil((searchResultsRecordCount - 1) / 10);

    setFormList({
      totalRecordCount: totalRecordCount - 1,
      searchResultRecordsCount: searchResultsRecordCount - 1,
      totalPages: newTotalPages,
      schemaList: list,
    });

    if (newTotalPages < totalPages && page === totalPages) {
      setPage(page - 1);
    }
  };

  const deleteForm = async (id: string, tenantId: string) => {
    await FormTemplateService.delete(id, tenantId);
    await FormManagementService.delete(id, tenantId);
    removeRow(id);
  };

  return (
    <div>
      <table id={tableId} className="govuk-table responsive-table">
        <caption className="govuk-table__caption" />
        <thead className="govuk-table__head">
          <tr className="govuk-table__row">
            {tableHeaders.map((row, idx) => (
              <th key={idx} scope="col" className="govuk-table__header">
                {row}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="govuk-table__body">
          {tableCells?.map((form) => (
            <tr className="govuk-table__row" key={form.schemaId}>
              {form ? (
                <>
                  <td data-th="Form Name" className="govuk-table__cell">
                    {form.schemaName}
                  </td>
                  <td data-th="Form ID" className="govuk-table__cell">
                    {form.schemaId}
                  </td>
                  <td data-th="Actions" className="govuk-table__cell govuk-table__cell--numeric">
                    <Link
                      to={{
                        pathname: `/${tenantName}/form-management/form-editor/${form.schemaId}`,
                        search: `?page=${page}`,
                      }}
                      className="govuk-button search-button"
                    >
                      Edit
                    </Link>
                    <button
                      className="govuk-button search-button delete-button"
                      onClick={() => deleteForm(form.schemaId, form.tenantId)}
                      disabled={checkDisabled(form.schemaId)}
                    >
                      Delete
                    </button>
                  </td>
                </>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationContext.Provider value={{ page, setPage }}>
        <div>
          Page {page} of {totalPages}
          <br />
          <br />
          <Pagination pageNumber={page} totalPages={totalPages} />
        </div>
      </PaginationContext.Provider>
    </div>
  );
};

export default FormSearchResultsTable;
