import { UserAccountModel } from '../models/users/user-account.model';
import { UserModel } from '../models/users/user.model';
import { UsersPaginated } from '../models/users/users-paginated.model';
import http from './http-common';

class UserDataService {
  create(tenantId: string, body: UserModel) {
    return http.post('/users/registration', body, {
      headers: {
        tenantId: tenantId,
      },
    });
  }

  activateAccount(tenantId: string, activationCode: string) {
    return http.post(
      '/users/activate',
      { code: activationCode },
      {
        headers: {
          tenantId: tenantId,
        },
      },
    );
  }

  resendActivationCode(tenantId: string, emailAddress: string) {
    return http.post(
      '/users/resendActivationCode',
      { emailAddress: emailAddress },
      {
        headers: {
          tenantId: tenantId,
        },
      },
    );
  }

  getAll(pageSize: number, page: number, searchText?: string) {

    return http.get<UsersPaginated>(
      `/usersManagement?pageSize=${pageSize}&page=${page}`, {
        params: {
          searchText: searchText
        }
      }
    );
  }

  get(userId: string, isExternalId = true) {
    return http.get<UserAccountModel>(
      `/usersManagement/${userId}?selectByExternalId=${isExternalId}`,
    );
  }

  update(userId: string, body: UserAccountModel) {

    return http.put<UserAccountModel>(
      `/usersManagement/${userId}`, body
    );
  }
}

export default new UserDataService();
