import { FC, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { FormAssessmentResponse } from '../../models/forms/form-assessment-response.model';
import { FormEntity } from '../../models/forms/form-entity';
import { FormRequest } from '../../models/forms/form-request';
import { UpdateFormRequest } from '../../models/forms/update-form-request';
import { saveForm, updateForm } from '../../slices/form/form-assessment-slice';

export interface ConfirmtionProps {
  onConfirm: () => void;
  message: string;
  assessment: FormAssessmentResponse;
  formValues: FieldValues;
  schemaId: string;
  onSaveForm: (formEntities: FormEntity[]) => void;
  deleteFileOnSave: () => void;
  fileNameToDelete: string;
}

const ConfirmationModal: FC<ConfirmtionProps> = (props) => {
  const [values] = useState(props.formValues)
  const dispatch = useAppDispatch();
  const formId = props.assessment?.formId;

  const handleSaveForm = (formPost: FormRequest) => {
    const args = {
      formRequest: formPost
    }

    dispatch(saveForm(args))
      .unwrap()
      .then()
      .catch((err) => {
        console.error('Error:', err);
      });
  }

  const handleUpdateForm = (formId: string, updateFormRequest: UpdateFormRequest) => {
    const args = {
      formId: formId,
      updateformRequest: updateFormRequest
    }
    dispatch(updateForm(args))
      .unwrap()
      .then()
      .catch((err) => {
        console.error('Error:', err);
      });
  }

  const onSave = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()

    let result: FormEntity[] = new Array<FormEntity>()
    let filteredResult: FormEntity[] = new Array<FormEntity>()

    result = Object.keys(values).map(key => ({
      id: key,
      value:
        values[key]?.toString().toLowerCase().includes('filelist')
          ? values[key]?.length > 0 ? values[key][0]?.name
            : props.assessment?.entityValues?.find(x => x.id == key)?.value
              ? props.assessment?.entityValues?.find(x => x.id == key)?.value : 'No file uploaded'
          : values[key]?.toString().trim()
    }));

    if (props.fileNameToDelete.length > 0) {
      props.deleteFileOnSave();
    }

    if (props.assessment) {
      result = props.assessment.entityValues.concat(result);      
      result = result.filter(x => x.value !== undefined);

      const ids = result.map(k => k.id)
      const filtered = result.filter(({ id }, idx) => !ids.includes(id, idx + 1))
      filteredResult = filtered;

      const updateFormPost: UpdateFormRequest = {
        entityValues: filtered
      }

      handleUpdateForm(formId, updateFormPost);
    } else {
      const formPost: FormRequest = {
        schemaId: props.schemaId,
        entityValues: result
      }
      filteredResult = result;
      handleSaveForm(formPost);
    }
    
    props.onSaveForm(filteredResult);
    props.onConfirm()
  }

  return (
    <>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <div className="govuk-warning-text">
            <span className="govuk-warning-text__icon" aria-hidden="true">!</span>
            <strong className="govuk-warning-text__text">
              {props.message}
            </strong>
          </div>
        </div>
      </div>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <button
            id="leave-button"
            className="govuk-button govuk-button--secondary 
            govuk-!-padding-left-4 govuk-!-padding-right-4 govuk-!-margin-right-4"
            data-module="govuk-button"
            aria-label="Leave without saving"
            onClick={props.onConfirm}>
            Leave without saving
          </button>
          <button
            id="save-button"
            className="govuk-button govuk-!-padding-left-4 govuk-!-padding-right-4 "
            data-module="govuk-button"
            aria-label="Save and leave"
            onClick={(e) => onSave(e)}>
            Save and <br />leave
          </button>
        </div>
      </div>
    </>
  )
}

export default ConfirmationModal;