interface LabelFieldProps {
    label: string;
    name: string;
    className?: string;
  }
  
const LabelField: React.FC<LabelFieldProps> = ({ label, name, className }) => {
  return (
    <>
      <label id={`${name}-hint`} className={`${className} govuk-label govuk-label--s`} htmlFor={name}>
        {label}
      </label>
    </>
  );
};
  

export default LabelField;