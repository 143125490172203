import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/users/user-slice'
import tenantReducer from '../slices/tenants/tenant-slice';
import formTemplatesReducer from '../slices/formSchema/formtemplate-slice';
import fileUploadReducer from '../slices/form/file-upload-slice';
import formAssessmentReducer from '../slices/form/form-assessment-slice';
import citizenReducer from '../slices/citizens/citizen-slice';
import sessionReducer from '../slices/sessions/session-slice';
import formsReducer from '../slices/form/form-management-slice';

const reducer =  {
  users: userReducer,
  tenants: tenantReducer,
  formTemplates: formTemplatesReducer,
  fileUpload: fileUploadReducer,
  formAssessments: formAssessmentReducer,
  sessions: sessionReducer,
  citizens: citizenReducer,
  forms: formsReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
