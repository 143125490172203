import { Fragment, InputHTMLAttributes } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { InputType } from '../../input-interface';

export interface InputElementProps
  extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<FieldValues>,
  validationSchema: any,
  label: string,
  type: InputType,
  id: string,
}

const RadioButton = ({ register, validationSchema, label, type, id, value, ...props }: InputElementProps) => {
  return (
    <Fragment>
      <div className='govuk-radios__item'>
        <input 
          key={id}
          {...register(props.name!, validationSchema)}
          className='govuk-radios__input' 
          id={id} 
          type={type}
          value={value}
          {...props} 
        />
        <label className='govuk-label govuk-radios__label' htmlFor={id} >
          {label}
        </label>
      </div>
    </Fragment>
  );
};

export default RadioButton;