import { Fragment, useState } from 'react';
import { OptionsModel } from '../../../../models/formSchema/options';
import ConditionalQuestion from '../conditions/conditional-questions';
import { InputElementProps } from '../input-interface';

import './radio-button-field.scss';
import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const RadioButtonField = ({
  name,
  formValue,
  label,
  labelHeading,
  register,
  errors,
  helper,
  options,
  required,
  type,
  validationSchema,
  className,
  conditions,
  entities,
  questions,
  setError,
  suffix,
  control,
  setValue,
  getValues,
  ...props }: InputElementProps) => {
  let radiobuttonfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required }
    });
    radiobuttonfield = field;
  }
  
  const [radioValue, setRadioValue] = useState(
    formValue && formValue?.entityValues?.filter(i => i.id === name)[0]?.value
  );
  const [showCondition, setShowCondition] = useState(
    formValue && formValue?.entityValues?.filter(i => i.id === name)[0]?.value == conditions[0]?.value);
  const hasConditions = conditions != null && conditions.length;

  const handleChange = (e: any, value: any) => {
    setRadioValue(String(e));
    if (hasConditions)
      setShowCondition(e == value);
  };

  const renderOptions = () => {
    return options && options.map(({ text, value }: OptionsModel, idx: number) => {
      const shortOptionLabel = text.replace(/\s+/g, '');
      const optionId = (`radio-option-${shortOptionLabel}`).toLowerCase();

      return (
        <Fragment key={`fragment-${optionId}`}>
          <div className='govuk-radios__item' key={optionId}>
            <input
              {...register(name!, validationSchema)}
              className='govuk-radios__input'
              id={`${name}-${value}`}
              name={name}
              key={optionId}
              type={type}
              value={value}
              aria-label={`radio-button-${value}`}
              aria-required={required ? true : false}
              aria-invalid={errors[name!] ? 'true' : 'false'}
              aria-errormessage={`${name}-err`}
              role="radio"
              checked={value === radioValue}
              onChange={(e) => {
                handleChange(e.currentTarget.value, conditions !== undefined && conditions[0]?.value);
                if (radiobuttonfield) {
                  radiobuttonfield.onChange(e.currentTarget.value);
                }                
              }}
            />
            <label
              className='govuk-label govuk-radios__label'
              htmlFor={`${name}-${value}`}>
              {text}
            </label>
          </div>
          {showCondition && value === conditions[0]?.value &&
            <ConditionalQuestion
              setError={setError}
              register={register}
              errors={errors}
              formValue={formValue!}
              questions={questions}
              entities={entities}
              suffix={suffix}
              setValue={setValue}
              getValues={getValues} />
          }
        </Fragment>
      )
    })
  }

  return (
    <fieldset className='govuk-fieldset govuk-!-margin-bottom-6'>
      <legend className='govuk-fieldset__legend legend__fieldset govuk-!-margin-bottom-3'>
        <b className='govuk-fieldset__legend--s govuk-!-margin-right-1'>
          {labelHeading}
        </b>
      </legend>
      {helper &&
        <div id="main-radio-buttons-hint" className="govuk-label" dangerouslySetInnerHTML={{ __html: helper }} />
      }
      <div id={`radio-button-${name}-hint`} className='govuk-radios' data-module='govuk-radios'>
        {renderOptions()}
      </div>
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message">
        {errors[name!] && errors[name!].type === 'required' &&
          'This field is required'
        }
        {errors[name!] && errors[name!].type === 'pattern' &&
          errors[name!].message
        }
      </span>
    </fieldset>
  )
}

export default RadioButtonField;