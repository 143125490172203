import http from './http-common';
import { FormSubmitRequest } from '../models/forms/form-submit-request';

class FormAssessmentService {
  submit(body: FormSubmitRequest, token: string | null = null) {
    let url = '/Submits/submit/';
    if (token) {
      url += `?token=${encodeURIComponent(token)}`;
    }
    return http.post<string>(url, body);
  }
}

export default new FormAssessmentService();