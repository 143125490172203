import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import fileUploadService from '../../services/fileUpload.service';

export const fileExists = createAsyncThunk(
  'fileExists/get', 
  async (
    {
      formId,
      fileName,
    }: { formId: any, fileName: string },
    thunkApi,
  ) => {
    try {
      const response = await fileUploadService.fileExists(fileName, formId);
      return response;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FileExistsState {
  loading: boolean;
  error: string | null;
  urn: string | null
}

const initialState = {
  loading: false,
  error: null,
  urn: null
} as FileExistsState

const fileExistsSlice = createSlice({
  name: 'fileExists',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fileExists.pending, (state) => {
        state.loading = true;
      })
      .addCase(fileExists.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.urn = action.payload;
      })
      .addCase(fileExists.rejected, (state, action: PayloadAction<any>) => {
        state.urn = null;
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.urn = null;
      });
  },
});

const { reducer } = fileExistsSlice;
export default reducer;