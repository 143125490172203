import { CSSProperties } from 'react';
import { FadeLoader } from 'react-spinners';
import './loader.scss';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto'
};

const LoadingSpinner = () => {

  return (
    <div id="loading" className="loading">

      <FadeLoader
        color={'#000000'}
        loading={true}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default LoadingSpinner;