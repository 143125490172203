import './error-page.scss';
import { useMsal } from '@azure/msal-react';
import { lazy, Suspense } from 'react';
import { loginRequest } from '../../authConfig';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../components/loader/loader';
const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

const ErrorPage = () => {
  const { search } = useLocation();
  const { instance } = useMsal();
  loginRequest.state = window.location.pathname;
  const queryParams = new URLSearchParams(search);
  const errorTitle = queryParams.get('errorTitle');
  const errorDescription = queryParams.get('errorDescription');

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <main className='govuk-main-wrapper govuk-width-container main__container' id='main-content' role='main'>
          <div className='error-page-main'>
            <div className='govuk-width-container'>
              <div className="govuk-grid-row error-page-container">
                <div className="govuk-grid-column-full govuk-!-margin-bottom-0 no-min-height cpcard__wrapper">
                  <h2 className="govuk-heading-s govuk-!-font-weight-bold">{errorTitle}</h2>
                  <div className="govuk-body govuk-!-margin-bottom-4 justified">{errorDescription}</div>
                  <button
                    id="button-signin"
                    role="button"
                    data-testid="button-signin"
                    className="govuk-button margin__top--s button__width-3"
                    data-module="govuk-button"
                    onClick={() => instance.loginRedirect(loginRequest)}
                    type="button">
                      Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </Suspense>
  )
}

export default ErrorPage;