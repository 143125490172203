import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantModel } from '../../models/tenants/tenant-model';
import sessionService from '../../services/session.service';
import TenantDataService from '../../services/tenant.service';
import { TenantFileConfigurationModel } from '../../models/tenants/tenant-file-configuration.model';
import { tenantConfig } from '../../constants/tenant-config-constants';

export function getCurrentTenantName(): string {
  const cachedConfig = JSON.parse(sessionStorage.getItem('config') as string);
  const loc = window.location.pathname.split('/');

  if (sessionService.session != null) return sessionService.session.tenant;

  return loc[1] !== 'msal-signin' ? loc[1] : cachedConfig ? cachedConfig.name : '';
}

export async function setStylesheet(obj: TenantModel) {
  const response = await TenantDataService.getBlob(obj.tenantCss.blobName);
  const reader = new FileReader();
  const css = await new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(response.data);
  });

  const style = document.createElement('style');
  style.textContent = css;

  document.head.appendChild(style);

  return style;
}

export const getTenantConfigurationById = createAsyncThunk(
  'tenants/getConfigurationByTenantId',
  async (_, thunkApi) => {
    const tenantId = getCurrentTenantName();
    try {
      const response = await TenantDataService.getTenantById(tenantId);
      sessionStorage.setItem('config', JSON.stringify(response.data));
      sessionStorage.setItem('configTimestamp', response.data.cacheDuration.toString());
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const saveTenantConfiguration = createAsyncThunk(
  'tenants/save',
  async ({ tenantPostObj }: { tenantPostObj: TenantModel }, thunkApi) => {
    try {
      const response = await TenantDataService.create(tenantPostObj);
      response.status;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const getTenantFileConfig = createAsyncThunk(
  'tenants/getFileConfiguration',
  async (_, thunkApi) => {
    try {
      const response = await TenantDataService.getFileConfig();
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface TenantState {
  loading: boolean;
  error: string | null;
  entities: TenantModel;
  fileConfig: TenantFileConfigurationModel | null;
}

const initialState = {
  loading: false,
  error: null,
  entities: {},
  fileConfig: null,
} as TenantState;

const tenantSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTenantConfigurationById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTenantConfigurationById.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getTenantConfigurationById.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addCase(getTenantFileConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenantFileConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.fileConfig = action.payload;
      })
      .addCase(getTenantFileConfig.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        if (!state.entities || !state.fileConfig) {
          state.entities = tenantConfig;
          state.fileConfig = { fileSize: 0, fileType: [] };
        }
      });
  },
});

const { reducer } = tenantSlice;
export default reducer;
