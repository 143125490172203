
export const errorMessage = (
  name: string,  
  message: string, 
  type: string,
  required: boolean, 
  errors: any,
  suffix?: string, 
) => {
  suffix = suffix ? suffix : '';
  
  const errorRequiredValidation = (required === true && 
  errors[`${name}${suffix}`] && required === true && 
  errors[`${name}${suffix}`].type === type && 
  <span id={`${name}${suffix}-err`} aria-live="assertive">
    {message}
  </span>)

  return errorRequiredValidation;
}

export const classNameError = (
  name: string, 
  required: boolean, 
  errors: any,
  suffix?: string, 
) => {
  suffix = suffix ? suffix : '';

  const errorValidation = (required === true && !errors[`${name}${suffix}`] && '' ||
  required === true && errors[`${name}${suffix}`] && 'govuk-input--error')

  return errorValidation;
}
