import LoadingSpinner from '../../components/loader/loader';
import { FormAssessmentResponse } from '../../models/forms/form-assessment-response.model';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { RootModel } from '../../models/formSchema/root';
import { getAllForms, getFormById } from '../../slices/form/form-assessment-slice';
import { getStep } from '../../helpers/helper-steps';
import ProtectedRoute from '../../routes/protected-route';
import { FormGenericStepContext } from '../../context/form-step-context';
import { useSelector } from 'react-redux';
import { getFormTemplate } from '../../slices/formSchema/formtemplate-slice';

export type IProps = {
  assessmentContainerId: string,
  assessment: FormAssessmentResponse,
  paragraph: string[],
  overviewMenuBackButtonText?: string,
  overviewMenubackButtonPath?: string,
  schemaId: string
}

const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));
const OverviewMenu = lazy(() => import('../../components/forms/assessment/overview-menu'));

const AssessmentForm = (props: IProps): JSX.Element => {
  const [step, setStep] = useState<number>(-1);
  const [summary, setSummary] = useState<boolean>(false);
  const [conditionalStep, setConditionalStep] = useState<number>(0);
  const [urn, setUrn] = useState([{ id: '', value: '' }]);
  const [fileUploadObj, setFile] = useState<any>();
  const [name, setFilePropName] = useState<string>('');
  const [submitData, setSubmitData] = useState<any>();
  const dispatch = useAppDispatch();
  const [rootModel, setRootModel] = useState<RootModel | undefined>()
  const [formAssessment, setFormAssessment] = useState<FormAssessmentResponse>(props.assessment)
  const tenant = useSelector((state: any) => state.tenants.entities);

  const id = props.schemaId;

  useEffect(() => {
    dispatch(getFormTemplate({ id }))
      .unwrap()
      .then((obj) => {
        setRootModel(obj)
      }).catch(err => {
        console.error('Error', err);
      });
  }, [dispatch]);

  useEffect(() => {
    if (submitData) {
      setFormAssessment(submitData);
    } else if (!formAssessment) {
      setTimeout(() => {
        dispatch(getAllForms())
          .unwrap()
          .then((forms) => {
            setFormAssessment(forms && forms.filter(form => form.schemaId === id)[0])
          })
          .catch((err) => {
            console.error('Err', err);
          });
      }, 500)
    } else {
      setTimeout(() => {
        const formId = formAssessment.formId
        dispatch(getFormById({ formId }))
          .unwrap()
          .then((form) => {
            setFormAssessment(form && form)
          })
          .catch((err) => {
            console.error('Err', err);
          });
      }, 500)
    }
  }, [submitData])

  const handleStep = (stepState: number) => {
    setStep(stepState);
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          <div id={props.assessmentContainerId} className='govuk-width-container govuk-!-margin-top-6'>
            <main className='govuk-main-wrapper main__container' id='main-content' role='main'>
              <div className='govuk-grid-row'>
                <div className="panel__container">
                  <div className="govuk-grid-column-full">
                    <div className="govuk-grid-row govuk-panel care__portal-panel">
                      <div className='govuk-panel'>
                        <FormGenericStepContext.Provider value={{
                          step, setStep, summary, setSummary,
                          conditionalStep, setConditionalStep, urn, setUrn,
                          fileUploadObj, setFile, name, setFilePropName, submitData, setSubmitData
                        }}>
                          {step < 0 &&
                            <OverviewMenu
                              handleStep={handleStep}
                              formData={rootModel!}
                              formAssessment={formAssessment}
                              tenant={tenant}
                              paragraph={props.paragraph}
                              urn={urn}
                              backButtonText={props.overviewMenuBackButtonText}
                              backButtonPath={props.overviewMenubackButtonPath}
                            />
                          }
                          {step >= 0 && rootModel !== undefined &&
                            getStep(formAssessment, step, rootModel, tenant, props.paragraph)
                          }
                        </FormGenericStepContext.Provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </ProtectedRoute>
      </DashboardLayout>
    </Suspense>
  );
}

export default AssessmentForm;