import sessionService from '../services/session.service';
import { SessionErrorType } from '../models/session/session-error-type';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, tokenRequest } from '../authConfig';
export const msalInstance = new PublicClientApplication(msalConfig);

class SessionErrorHandlerService {
    handleSessionError(errorResponse: any) {
        if (!errorResponse || !errorResponse.data) {
            return;
        }

        switch (errorResponse.data.errorType) {
            case SessionErrorType.InvalidCitizenSession: {
                if (errorResponse.data.sessionResponse) {
                    sessionService.session = {
                        session: errorResponse.data.sessionResponse,
                        tenantId: sessionService.session?.tenantId ?? '',
                        tenant: sessionService.session?.tenant ?? ''
                    }
                }
                const modalError = {
                    showErrorModal: true,
                    errorTitle: 'Sorry',
                    errorMessage: 'You no longer have permission to carry out actions for this individual, we have taken you back to the main page'
                };
                sessionStorage.setItem('modal-error', JSON.stringify(modalError));
                const path = `/${sessionService.session?.tenant}/dashboard`;
                window.location.href = path;
                break;
            }
            case SessionErrorType.InactiveUserSession:
            case SessionErrorType.RevokedUserSession:
            case SessionErrorType.InvalidUserSession: {
                if (!window.location.href.includes(`/${sessionService.session?.tenant}/error`)) {
                    const errorTitle = encodeURIComponent('Signed Out');
                    const errorDescription = encodeURIComponent(errorResponse.data.errorMessage);
                    const path = `/${sessionService.session?.tenant}/error?errorTitle=${errorTitle}&errorDescription=${errorDescription}`;
                    const logoutRequest = {
                        postLogoutRedirectUri: path,
                    };

                    msalInstance.logoutRedirect(logoutRequest);
                }
                break;
            }
        }
    }
}

export default new SessionErrorHandlerService();