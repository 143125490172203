import { FC } from 'react';
import './search-count.scss';

export type Props = {
    objectName: string;
    totalFoundRecords: number;
    totalRecords: number;
};

const SearchCount: FC<Props> = ({ objectName, totalFoundRecords, totalRecords }: Props) => {
  return (
    <div className='search-count'>
      Found {totalFoundRecords} {objectName} out of {totalRecords}
    </div>
  )
}

export default SearchCount;