import './pagination.scss';
import { usePaginationStateContext } from '../../context/form-context';

export type IProps = {
    pageNumber: number,
    totalPages: number
}

const Pagination = ({pageNumber, totalPages}: IProps): JSX.Element => {
  const { setPage } = usePaginationStateContext();

  const getPreviousPage = (e: React.FormEvent<EventTarget>, page: number) => {
    e.preventDefault()

    setPage(page - 1)
  }

  const getNextPage = (e: React.FormEvent<EventTarget>, page: number) => {
    e.preventDefault()

    setPage(page + 1)
  }

  return (
    <nav id="pagination" className="govuk-pagination govuk-pagination pagination" 
      role="navigation" aria-label="results">
      { pageNumber != 1 && <div className="govuk-pagination__prev">
        <a id="previous" className="govuk-link govuk-pagination__link" 
          rel="prev" onClick={(e => getPreviousPage(e, pageNumber))}>
          <svg className="govuk-pagination__icon" xmlns="http://www.w3.org/2000/svg" 
            height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
            <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 
                1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>
          </svg>
          <span className="govuk-pagination__link-title">Previous</span>
        </a> 
      </div> 
      }
      { pageNumber != totalPages && <div className="govuk-pagination__next">
        <a id="next" className="govuk-link govuk-pagination__link" rel="next" 
          onClick={(e => getNextPage(e, pageNumber))}> 
          <span className="govuk-pagination__link-title">Next</span>
          <svg className="govuk-pagination__icon" xmlns="http://www.w3.org/2000/svg" 
            height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
            <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 
            3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>
          </svg> 
        </a>
      </div>
      }
    </nav>
  )
}

export default Pagination