import { FC, ReactNode} from 'react';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import './dashboard-layout.scss';
import { useSelector } from 'react-redux';

export type Props = {
  children?: ReactNode;
};

const DashboardLayout: FC<Props> = ({ children }: Props) => {
  const tenant = useSelector((state: any) => state.tenants.entities);
  
  return (
    <>
      <div data-testid="dashboardId">
        <Header tenant={tenant} />
        {children}
        <Footer tenant={tenant} />
      </div>
    </>
  );
};

export default DashboardLayout;
