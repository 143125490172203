import { FC } from 'react'
import ReactDOM from 'react-dom';
import StatusModal from './status-modal';
import './modal.scss';

export interface ModalProps {
  isOpen: boolean;
  modalContent: JSX.Element;
  headerText?: string;
  modalBoxStyle?: string;
  onCancel: () => void;
}

const Modal: FC<ModalProps> =  ({
  isOpen,
  modalContent,
  headerText,
  modalBoxStyle = 'modal-box',
  onCancel
}) => {

  const modal = (
    <>
      <div className='modal'
        aria-modal aria-labelledby='Modal Dialog'
        tabIndex={-1} 
        role="dialog">
        <div className={modalBoxStyle}>
          <span id="close" onClick={onCancel} className="close-button topright">&times;</span>
          <div className="govuk-width-container">
            <main className="govuk-main-wrapper govuk-!-padding-2">
              {headerText && 
              <div className="govuk-grid-row govuk-!-margin-top-6">
                <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center">
                  {headerText}
                </div>
              </div>
              }
              {modalContent}
            </main>
          </div>
        </div>
      </div>
    </>
  )
  return (
    isOpen ? ReactDOM.createPortal(modal, document.body) : null
  )
}

export default Modal;