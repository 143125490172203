import { FormAssessmentResponse } from '../../../models/forms/form-assessment-response.model';
import AssessmentForm from '../assessment-form';
import { useParams } from 'react-router-dom';

export type IProps = {
  formassessmentResponse: FormAssessmentResponse[] | undefined,
}

const CustomAssessment = ({formassessmentResponse}: IProps): JSX.Element => {
  const { customId } = useParams<{ customId: string }>();

  const paragraph = [
    '<p>Please fill in the form it has been broken down into small sections ' +
    'that you can jump to and complete separately. Keep track of your completion on this page</p>'
  ]

  return (
    <AssessmentForm
      assessmentContainerId='custom-assessment'
      assessment={
        formassessmentResponse! &&
        formassessmentResponse.find((x) => x.schemaId === customId)!
      }
      paragraph={paragraph}
      schemaId={customId!} />
  );
}

export default CustomAssessment;