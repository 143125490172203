import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormsManagement } from '../../models/forms/form-management.model';
import formManagementService from '../../services/form-management.service';

export const getAllSchemas = createAsyncThunk(
  'schema/getAll',
  async (
    { tenantId, searchText, pageSize, page }: {tenantId: string, pageSize: number; page: number, searchText?: string;},
    thunkApi,
  ) => {
    try {
      const response = await formManagementService.getAll(tenantId, pageSize, page, searchText);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FormState {
  loading: boolean;
  error: string | null;
  forms: FormsManagement | object;
}

const initialState = {
  loading: false,
  error: null,
  forms: {},
} as FormState;

const formSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllSchemas.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSchemas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.forms = action.payload;
      })
      .addCase(getAllSchemas.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.forms = {};
      });
  },
});

const { reducer } = formSlice;
export default reducer;
