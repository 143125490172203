import UseSwitch from '../../../../hooks/use-switch';
import { QuestionModel } from '../../../../models/formSchema/question';
import { IRepeatElementProps } from '../input-interface';

import './repeatable-fields.scss';

const RepeatableFieldComponent = ({ 
  formValue, 
  questions, 
  steps,  
  setError, 
  register, 
  errors, 
  clearErrors,
  conditionalStep,
  index,
  setFile,
  setFilePropName,
  setValue,
  getValues,
}: IRepeatElementProps): JSX.Element => {

  const renderFields = steps[conditionalStep].entities.map((res: any, idx: number) => {
    return questions?.filter((i: { id: string }) => i.id === res.id)
      .map((field: QuestionModel) => {
        const f: QuestionModel = {
          id: index === 1 ? field.id : `${field.id}${index.toString()}`,
          text: field.text,
          type: field.type,
          mandatory: field.mandatory,
          properties: field.properties,
          options: field.options,
          helper: field.helper,
          validation: field.validation
        }
        return UseSwitch(f, register, idx, errors, setError, 
          formValue, questions, res, setValue, getValues, clearErrors, setFile, setFilePropName, index)
      });
  });
  
  return (
    <>
      {renderFields}
    </>
  )
}

export default RepeatableFieldComponent;