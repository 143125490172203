import { FC } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormAssessmentResponse } from '../../models/forms/form-assessment-response.model';
import { FormEntity } from '../../models/forms/form-entity';

export interface ConfirmtionProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  assessment: FormAssessmentResponse;
  formValues: FieldValues;
  schemaId: string;
  onSaveForm: (formEntities: FormEntity[]) => void;
}

const SubjectlessConfirmationModal: FC<ConfirmtionProps> = (props) => {
  return (
    <>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <div className="govuk-warning-text">
            <span className="govuk-warning-text__icon" aria-hidden="true">
              !
            </span>
            <strong className="govuk-warning-text__text">{props.message}</strong>
          </div>
        </div>
      </div>
      <div className="govuk-grid-row govuk-!-margin-top-7">
        <div className="govuk-grid-column-full govuk-!-padding-2 flex__text-align--center-content-only">
          <button
            id="leave-button"
            className="govuk-button govuk-button--secondary 
            govuk-!-padding-left-4 govuk-!-padding-right-4 govuk-!-margin-right-4"
            data-module="govuk-button"
            aria-label="Leave without saving"
            onClick={props.onConfirm}
          >
            Back to overview
          </button>
          <button
            id="save-button"
            className="govuk-button govuk-!-padding-left-4 govuk-!-padding-right-4 "
            data-module="govuk-button"
            aria-label="Continue editing"
            onClick={props.onCancel}
          >
            Continue editing
          </button>
        </div>
      </div>
    </>
  );
};

export default SubjectlessConfirmationModal;
