import { useEffect, useState } from 'react';

const useDebounce = <T>(value: any, delay: number): T => {
  const [debounceValue, setDebounceValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value), delay || 500;
    }, delay);

    return () => {
      clearTimeout(handler);
    }
  }, [value, delay])

  return debounceValue;
}

export default useDebounce;