export const b2cPolicies = {
  names: {
    signUpSignin: 'B2C_1_signin',
    passwordReset: 'B2C_1_PasswordReset'
  },
  authorities: {
    signUpSignin: {
      authority: `https://${window.REACT_APP_B2C_SUB_DOMAIN}.b2clogin.com/${window.REACT_APP_B2C_SUB_DOMAIN}.onmicrosoft.com/B2C_1_signin`
    },
    passwordReset: {
      authority: 
      `https://${window.REACT_APP_B2C_SUB_DOMAIN}.b2clogin.com/${window.REACT_APP_B2C_SUB_DOMAIN}.onmicrosoft.com/B2C_1_PasswordReset`
    }
  },
  authorityDomain: `${window.REACT_APP_B2C_SUB_DOMAIN}.b2clogin.com`
}